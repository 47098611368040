/**
 *
 *
 *
 */
import React from 'react';
import styled, { keyframes } from 'styled-components';


/**
 *
 *
 *
 */
export default function Spinner(props) {

  return (
    <S.Container $scale={props.$scale} style={props.style}>
      {Array.from({ length: 12 }).map(
        (_, i) => <S.Div $i={i} key={i} $bg={props.$bg} />
      )}
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  transform: scale(${p => p.$scale ?? 0.3});
`;

S.Rotate = keyframes`
  0%   { opacity: 1; }
  100% { opacity: 0; }
`;

S.Div = styled.div`
  transform-origin: 40px 40px;
  animation: ${S.Rotate} 1.2s linear infinite;
  transform: rotate(${p => p.$i * 30}deg);
  animation-delay: ${p => -1.2 + p.$i * 0.1}s;
  &:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 20px;
    border-radius: 20%;
    background: ${p => p.$bg ?? 'rgb(208, 215, 222)'};
  }
`;
