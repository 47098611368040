/**
 *
 *
 *
 */
import React from 'react';
import * as Router from 'react-router-dom';
import styled, { ThemeProvider } from 'styled-components';
import { GlobeStyle, Theme } from '../_components/Style';
import Analytics from '../_components/Analytics';
import NotFound from '../_components/NotFound';
import Header from '../_components/Header';
import User from './pages/User';
import Home from './pages/Home';
import Register from './pages/Register';
import Settings from './pages/Settings';
import Login from './pages/Login';
import Forgot from './pages/Forgot';
import Reset from './pages/Reset';
import * as Store from './store';


/**
 *
 *
 *
 */
export default function Accounts() {
  return (
    <Store.Provider>
      <ThemeProvider theme={Theme.light}>
        <Router.BrowserRouter basename="/">
          <GlobeStyle />
          <Header />
          <Analytics />
          <S.PageContainer>
            <Router.Routes>
              <Router.Route path='/'           element={<Home />} />
              <Router.Route path='/register'   element={<Register />} />
              <Router.Route path='/login'      element={<Login />} />
              <Router.Route path='/forgot'     element={<Forgot />} />
              <Router.Route path='/reset/:tkn' element={<Reset />} />
              <Router.Route path='/settings'   element={<Settings />} />
              <Router.Route path='/:unix'      element={<User />} />
              <Router.Route path="*"           element={<NotFound />} />
            </Router.Routes>
          </S.PageContainer>
        </Router.BrowserRouter>
      </ThemeProvider>
    </Store.Provider>
  );
};


/**
 *
 *
 *
 */
const S = {};

S.PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  max-width: 1200px;
  padding-top: 60px;
  padding-bottom: 10px;
  margin: auto;
  height: 100vh;
  @media (max-width: 600px) {
    padding-top: 60px;
  }
`;