/**
 *
 *
 *
 */
export const FRG_GET_ME = `
  query AccountsGetMe {
    accounts_me {
      id
      first_name
      last_name
      email
      company
      is_manager
      location
      linkedin
      photo
      unix
      role
      public_key
      private_key
      created_at
      updated_at
    }
  }
`;


/**
 *
 *
 *
 */
export const FRG_GET_PROFILE = `
  query AccountsGetProfile($unix: String!) {
    accounts_get_user(unix: $unix) {
      id
      first_name
      last_name
      email
      company
      location
      linkedin
      photo
      unix
      public_key
      created_at
      updated_at
    }
  }
`;


/**
 *
 *
 *
 */
export const FRG_GET_USERS = `
  query AccountsGetUsers {
    accounts_get_users {
      id
      first_name
      last_name
      email
      company
      location
      linkedin
      photo
      unix
      public_key
      cohort {
        id
        name
        image
      }
      house {
        id
        name
        image
      }
      created_at
      updated_at
    }
  }
`;


/**
 *
 *
 *
 */
export const FRG_GET_USER_CALENDAR = `
  query AccountsGetUserCalendar($id: ID!) {
    accounts_get_user_calendar(id: $id)
  }
`;


/**
 *
 *
 *
 */
export const FRG_UPDATE_USER = `
  mutation AccountsUpdateUser(
    $linkedin: String,
    $company: String,
    $location: String,
    $public_key: String,
    $private_key: String
  ) {
    accounts_update_user(
      linkedin: $linkedin,
      company: $company,
      location: $location,
      public_key: $public_key,
      private_key: $private_key
    ) {
      company
      location
      linkedin
      public_key
      private_key
    }
  }
`;


/**
 *
 *
 *
 */
export const AUTH_LOGIN = `
  mutation AuthLogin($unix_email: String!, $password: String!) {
    auth_login(unix_email: $unix_email, password: $password)
  }
`;