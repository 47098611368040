/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import * as d3 from 'd3';


/**
 *
 *
 *
 */
export default function Hours(props) {

  const ref = React.useRef();
  React.useEffect(onInitialise, [ref.current, props.data, props.opts]);
  var { M, W, H } = props.opts; W = W - M.L - M.R; H = H - M.T - M.B;
  return (<S.SVG ref={ref} />);

  /**
   *
   *
   *
   */
  function onInitialise() {

    if (!ref.current) return;
    const mTime = [...R.pluck('date', props.months)]; // each date has format 'MM-YYYY'
    const hours = R.pluck('hours', props.months); // each hour is a float
    const xDomains = d3.extent(mTime.map(d => DateTime.fromFormat(d, 'yyyy-MM').toJSDate()));
    const barWidth = d3.scaleLinear().domain([400, 800]).range([15, 30])(W);
    const x = d3.scaleTime().domain(xDomains).range([barWidth, W - 1]);
    const y = d3.scaleLinear().domain([0, d3.max(hours)]).range([H, M.T]);

    const svg = d3.select(ref.current)
      .attr('width', W + M.L + M.R)
      .attr('height', H + M.T + M.B)
      .append('g')
      .attr('transform', `translate(${M.L},${M.T})`);

    svg.append('g')
      .attr('transform', `translate(0, ${H})`)
      .call(d3.axisBottom(x).ticks(props.months.length).tickFormat(d => DateTime.fromJSDate(d).toFormat('MMM')))
      .selectAll('line, path')
      .attr('stroke', 'rgb(208, 215, 222)');

    svg.append('g')
      .attr('transform', `translate(0, 0)`)
      .call(d3.axisLeft(y).ticks(5))
      .selectAll('line, path')
      .attr('stroke', 'rgb(208, 215, 222)');

    svg.selectAll('rect')
      .data(props.months)
      .enter().append('rect')
      .attr('x', d => x(DateTime.fromFormat(d.date, 'yyyy-MM').toJSDate()) - (barWidth / 2))
      .attr('y', d => y(d.hours) + 1)
      .attr('width', barWidth)
      .attr('height', d => H - y(d.hours))
      .attr('fill', d => (d.hours > props.minH ? 'rgb(56, 176, 0)' : '#FF6868'));

    svg.append('line')
      .attr('x1', 0)
      .attr('x2', W)
      .attr('y1', y(props.minH))
      .attr('y2', y(props.minH))
      .attr('stroke', 'black')
      .attr('stroke-width', 1)
      .attr('stroke-dasharray', '4 4');

    return () => { d3.select(ref.current).selectAll('*').remove(); };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.SVG = styled.svg``;