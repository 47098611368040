/**
 *
 *
 *
 */
import React from 'react';
import { useTheme } from 'styled-components';
import * as d3 from 'd3';


/**
 *
 *
 *
 */
const onTextScale = d3.scaleLinear().domain([0, 30]).range([24, 6]);
const onTextAngle = d3.scaleLinear().domain([0, 30]).range([0, -35]);
const onExtraSpace = d3.scaleLinear().domain([0, 30]).range([0, 30]);


/**
 *
 *
 *
 */
export default function BarChart(props) {

  const ref = React.useRef();
  const t = useTheme();
  const { W, H, M } = props.opts;
  const stats = React.useMemo(onDomainStats, [ref, props.data]);
  const M_B = M.B + stats.extraSpace;
  React.useEffect(onInitialise, [ref, props.data, t.isDark]);
  return (<svg ref={ref} />);

  /**
   *
   *
   *
   */
  function onInitialise() {

    if (!ref.current || !props.data || !props.data.length) return;
    const xDomain = props.data.map(d => d.x);
    const yDomain = [0, d3.max(props.data, d => d.y)];
    const xRange = [M.L, W - M.R];
    const yRange = [H - M_B, M.T];

    const xScale = d3.scaleBand().domain(xDomain).range(xRange).padding(0.1);
    const yScale = d3.scaleLinear().domain(yDomain).range(yRange);

    const svg = d3.select(ref.current)
      .attr('width', W)
      .attr('height', H)
      .attr('viewBox', [0, 0, W, H])
      .attr('style', 'max-width: 100%; height: auto;');

    const g = svg.append('g')
      .attr('transform', 'translate(' + M.L + ',' + M.T + ')');

    g.selectAll('rect')
      .data(props.data)
      .enter().append('rect')
      .attr('x', d => xScale(d.x))
      .attr('y', d => yScale(d.y))
      .attr('width', xScale.bandwidth())
      .attr('height', d => (H - M_B) - yScale(d.y))
      .attr('fill', t.border);

    g.append('g')
      .attr('color', t.primary)
      .attr('transform', 'translate(0,' + (H - M_B) + ')')
      .call(d3.axisBottom(xScale))
      .selectAll('line, path')
      .attr('stroke', t.border);

    g.selectAll('.tick text')
      .attr('transform', `rotate(${stats.textAngle})`)
      .attr('text-anchor', stats.textAnchor)
      .attr('dy', `${stats.textdy}em`)
      .attr('dx', `${stats.textdx}em`)
      .style('font-size', `${stats.textSize}px`);

    g.append('g')
      .attr('color', t.primary)
      .call(d3.axisLeft(yScale).ticks(5))
      .selectAll('line, path')
      .attr('stroke', t.border);

    return () => svg.selectAll('*').remove();
  }

  /**
   *
   *
   *
   */
  function onDomainStats() {
    const maxTextSize = Math.max(...props.data.map(d => d.x.length));
    const xCounts = props.data.map(d => d.x).length;

    let textSize = onTextScale(maxTextSize);
    let textAngle = onTextAngle(maxTextSize);
    let extraSpace = onExtraSpace(maxTextSize);
    let textAnchor = 'middle';
    let textdy = 0.8;
    let textdx = 0;
    if (xCounts <= 4) textAngle = 0;
    if (textAngle != 0) { textAnchor = 'end'; }
    if (textAngle != 0) { textdy = 0.40; }
    if (textAngle != 0) { textdx = -0.5; }

    return { textSize, textAngle, textAnchor, textdy, textdx, extraSpace, maxTextSize, xCounts };
  }
}
