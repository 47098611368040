/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';


/**
 *
 *
 *
 */
export default function ProfileOther(props) {

  return (
    <S.Container>
      Other
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div``;