/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import * as d3 from 'd3';


/**
 *
 *
 *
 */
Timeline.defaultProps = {
  showToday: true,
  showFuture: false,
  stats: [],
  area: [],
  dots: [],
};


/**
 *
 *
 *
 */
export default function Timeline(props) {

  const ref = React.useRef();
  React.useEffect(onInitialise, [ref.current, props.data, props.opts, props.area]);
  var { M, W, H } = props.opts; W = W - M.L - M.R; H = H - M.T - M.B;
  return (<S.SVG ref={ref} />);

  /**
   *
   *
   *
   */
  function onInitialise() {

    if (!ref.current) return;
    let dotsTime = [...R.pluck('date', props.dots)];
    let areaTime = [...R.pluck('start', props.area), ...R.pluck('ended', props.area)];
    let curDates = [...dotsTime, ...areaTime, onToday()].sort((a, b) => DateTime.fromISO(a) - DateTime.fromISO(b));
    let padsDate = DateTime.fromISO(curDates[0]).minus({ days: 3 }).toString();
    let statDate = R.pluck('ds', props.stats).map(d => DateTime.fromISO(d).toString());
    let xDomains = d3.extent([padsDate, ...curDates, ...statDate], d => DateTime.fromISO(d).toJSDate());

    const svg = d3.select(ref.current)
      .attr('width', W + M.L + M.R)
      .attr('height', H + M.T + M.B)
      .append('g')
      .attr('transform', `translate(${M.L},${M.T})`);

    const x = d3.scaleTime()
      .domain(xDomains)
      .range([0, W - 1]);

    let dom = x.domain();
    const t = new Date();
    if (dom[1] > t && !props.showFuture) { dom[1] = t; x.domain(dom); }

    svg.append('rect')
      .attr('x', 0)
      .attr('y', 0)
      .attr('width', W)
      .attr('height', H)
      .attr('fill', 'rgba(246, 248, 250, 1)');

    svg.selectAll('.area')
      .data(props.area)
      .enter().append('rect')
      .attr('class', 'area')
      .attr('x', d => x(DateTime.fromISO(d.start).toJSDate()))
      .attr('y', 0)
      .attr('width', d => (x(DateTime.fromISO(d.ended).toJSDate()) - x(DateTime.fromISO(d.start).toJSDate())) || 0)
      .attr('height', H - 20)
      .attr('fill', d => d.$bg);

    svg.append('line')
      .attr('x1', 0)
      .attr('y1', H - 20)
      .attr('x2', W)
      .attr('y2', H - 20)
      .attr('stroke', 'rgb(208, 215, 222)')
      .attr('stroke-width', 1);

    props.showToday && svg.append('line')
      .attr('x1', x(DateTime.local().toJSDate()))
      .attr('y1', 0)
      .attr('x2', x(DateTime.local().toJSDate()))
      .attr('y2', H)
      .attr('stroke', 'red')
      .attr('stroke-width', 1);

    svg.selectAll('.stat')
      .data(props.stats)
      .enter().append('rect')
      .attr('class', 'area')
      .attr('x', d => x(DateTime.fromISO(d.ds).plus({hours: 16}).toJSDate()))
      .attr('y', H - 20)
      .attr('width', d => x(DateTime.fromISO(d.ds).plus({hours: 20}).toJSDate()) - x(DateTime.fromISO(d.ds).plus({hours: 16}).toJSDate()))
      .attr('height', 20)
      .attr('fill', 'black');

    svg.selectAll('.dot')
      .data(props.dots)
      .enter().append('circle')
      .attr('class', 'dot')
      .attr('cx', d => x(DateTime.fromISO(d.date).toJSDate()))
      .attr('cy', d => H / 2 - d.$idx * 15)
      .attr('r', 2)
      .attr('fill', d => d.$bg);

    svg.append('g')
      .attr('transform', `translate(0,${H})`)
      .call(d3.axisBottom(x).ticks(5))
      .selectAll('line, path')
      .attr('stroke', 'rgb(208, 215, 222)');

    const tips = d3.select(ref.current)
      .append('g')
      .attr('transform', `translate(${W + 10}, ${M.T})`)
      .selectAll('.tip')
      .data(onTopics())
      .enter();

    tips.each(function (d, i) {
      const parent = d3.select(this);

      if (d.$type === 'dots') {
        parent.append('circle')
          .attr('cx', 5)
          .attr('cy', i * 20 + 5)
          .attr('r', 5)
          .attr('fill', d => d.$bg);
      }

      if (d.$type === 'area') {
        parent.append('rect')
          .attr('x', 0)
          .attr('y', i * 20)
          .attr('width', 10)
          .attr('height', 10)
          .attr('fill', d => d.$bg);
      }
    });

    tips.append('text')
      .attr('x', 15)
      .attr('y', (d, i) => i * 20 + 8)
      .text(d => d.$topic)
      .attr('font-size', 10)
      .attr('font-family', 'Inter')
      .attr('font-weight', 400);

    return () => { d3.select(ref.current).selectAll('*').remove(); };
  }

  /**
   *
   *
   *
   */
  function onTopics() {
    const mix = R.concat(props.area, props.dots);
    const currTopics = mix.reduce((acc, cur) => {
      acc[cur.$topic] = { $topic: cur.$topic, $bg: cur.$bg, $type: cur.$type };
      return acc;
    }, {});

    currTopics['stats'] = { $topic: 'Online Platfrom', $bg: '#000', $type: 'area' };
    return R.values(currTopics).sort((a, b) => a.$type === 'area' ? -1 : 1).slice(0, 6);
  }

  /**
   *
   *
   *
   */
  function onToday() {
    const now = DateTime.now();
    const endOfToday = now.endOf('day');
    return endOfToday.set({ hour: 15, minute: 0, second: 0 }).toString();
  }
}


/**
 *
 *
 *
 */
const S = {};

S.SVG = styled.svg``;