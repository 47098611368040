/**
 *
 *
 *
 */
import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { darken } from 'polished';
import T from '../../_components/Typography';
import * as Store from '../store';


/**
 *
 *
 *
 */
export default function ImagePreviewUpload(props) {

  const currRef = React.useRef(null);
  const { state, dispatch } = React.useContext(Store.Context);
  const [currFile, setCurrFile] = React.useState(null);
  const [imgPreview, setImgPreview] = React.useState(null);
  React.useEffect(() => { onImgPreview(); }, [currFile]);
  const maybeImgSrc = imgPreview ?? (props.isMe ? state.user?.photo : props?.user?.photo);
  const initials = onGetInitials();

  return (
    <React.Fragment>
      <S.Container $isMe={props.isMe}>
        {maybeImgSrc ? <S.ImagePreview src={maybeImgSrc} /> : onShowInitials()}
        <S.InnerContainer
          onClick={() => currRef?.current?.click()}
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDrop}
        >
          <S.File
            onChange={onChange}
            ref={currRef}
            type={'file'}
            multiple={false}
          />
          <T.IX5 style={{ textAlign: 'center', lineHeight: '18px' }}>
            Click to browse or<br />drag and drop files
          </T.IX5>
        </S.InnerContainer>
      </S.Container>
      {currFile && (
        <div style={{ width: '100%', display: 'flex' }}>
          <S.Button onClick={onUpload} $color={'rgb(31, 136, 61)'}>
            <T.IM6 color="white">Upload</T.IM6>
          </S.Button>
          <div style={{ width: 10 }} />
          <S.Button onClick={onDiscard}>
            <T.IM6>Discard</T.IM6>
          </S.Button>
        </div>
      )}
    </React.Fragment>
  );

  /**
   *
   *
   *
   */
  function onShowInitials() {
    return (
      <S.InitialsContainer>
        <T.IG4>{initials}</T.IG4>
      </S.InitialsContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onGetInitials() {
    return [
      (props.user?.first_name ?? [])[0] ?? '',
      (props.user?.last_name ?? [])[0] ?? '',
    ].join('').trim();
  }

  /**
   *
   *
   *
   */
  function onDiscard(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    setCurrFile(null);
    setImgPreview(null);
  }

  /**
   *
   *
   *
   */
  function onImgPreview() {
    if (!currFile) return;
    const reader = new FileReader();
    reader.onloadend = () => setImgPreview(reader.result);
    reader.readAsDataURL(currFile);
  }

  /**
   *
   *
   *
   */
  async function onUpload(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const isDev = process.env.NODE_ENV === 'development';
    const currBase = isDev ? `http://localhost:8010` : `https://api.mlx.institute`;
    const data = new FormData();
    data.append('file', currFile);
    const opts = { withCredentials: true };
    const res = await axios.post(`${currBase}/profile_image_upload`, data, opts);
    dispatch({ type: 'SET_USER', payload: res.data.user });
    window.location.reload();
  }

  /**
   *
   *
   *
   */
  function onProgress(file) {
    return (evt) => {
      const progress = Math.round((evt.loaded * 100) / evt.total);
      const size = evt.total;
      console.log(`size`, size);
    };
  }

  /**
   *
   *
   *
   */
  function onChange(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const [file] = evt.target.files;
    setCurrFile(file);
  }

  /**
   *
   *
   *
   */
  function onDrop(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    const [file] = evt.target.files;
    setCurrFile(file);
  }

  /**
   *
   *
   *
   */
  function onDragOver(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    evt.currentTarget.setAttribute('draggable', 'true');
  }

  /**
   *
   *
   *
   */
  function onDragLeave(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    evt.currentTarget.removeAttribute('draggable');
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

S.InnerContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding-top: 5px;
  &:hover { cursor: pointer; }
  background: rgba(246, 248, 250, 0.8);
  &[draggable="true"] { cursor: grabbing; background: red; }
`;

S.File = styled.input`
  visibility: hidden;
  position: absolute;
  outline: none;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
`;

S.InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  border-bottom: 1px solid rgb(237, 239, 244);
  svg { &:hover { cursor: pointer; stroke: #1B1B1B; } }
  p { &:hover { cursor: pointer; color: #FD8C73; } }
`;

S.ImagePreview = styled.img`
  z-index: 1;
  position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

S.Button = styled.button`
  width: 100%;
  height: 35px;
  border-radius: 6px;
  background: ${p => p.$color ?? 'rgb(243, 244, 246)'};
  border: 1px solid rgba(31, 35, 40, 0.15);
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color ?? 'rgb(243, 244, 246)'))}; }
  margin-top: 20px;
`;

S.InitialsContainer = styled.div`
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  p { font-size: 100px; }
`;

S.Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgb(237, 239, 244);
  ${p => p.$isMe && `&:hover {
    cursor: pointer;
    ${S.ImagePreview} { z-index: 0; }
    ${S.InitialsContainer} {
      z-index: 0;
      position: absolute;
      opacity: 0.3;
    }
  }`}
`;
