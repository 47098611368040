/**
 *
 *
 *
 */
import React from 'react';
import forge from 'node-forge';
import { darken } from 'polished';
import styled from 'styled-components';
import MonacoEditor from '../../_components/MonacoEditor';
import T from '../../_components/Typography';
import * as store from '../store';


/**
 *
 *
 *
 */
export default function GenerateKeys(props) {

  const { state, actions } = React.useContext(store.Context);
  const [publicKey, setPublicKey] = React.useState();
  const [privateKey, setPrivateKey] = React.useState();
  React.useEffect(() => { setPublicKey(state.user?.public_key); }, [state.user]);
  React.useEffect(() => { setPrivateKey(state.user?.private_key); }, [state.user]);

  return (
    <S.Container>
      <S.EditorContainer>
        <S.InnerEditor>
          <MonacoEditor {...onPubMonacoProps()} />
        </S.InnerEditor>
        <S.InnerEditor>
          <MonacoEditor {...onPrvMonacoProps()} />
        </S.InnerEditor>
      </S.EditorContainer>
      <S.ButtonContainer>
        <S.Button onClick={onGenerateKeys}>
          <T.IM5 color={'white'}>
            Generate
          </T.IM5>
        </S.Button>
        <S.Button onClick={() => actions.onUpdateKeys(publicKey, privateKey)}>
          <T.IM5 color={'white'}>
            Update
          </T.IM5>
        </S.Button>
      </S.ButtonContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onPubMonacoProps() {
    return {
      key: publicKey,
      language: 'text',
      value: publicKey,
      onSave: () => {},
      readOnly: true,
      lineNumbers: 'off',
    };
  }

  /**
   *
   *
   *
   */
  function onPrvMonacoProps() {
    return {
      key: privateKey,
      language: 'text',
      value: privateKey,
      onSave: () => {},
      readOnly: true,
      lineNumbers: 'off',
    };
  }

  /**
   *
   *
   *
   */
  async function onGenerateKeys() {
    forge.pki.rsa.generateKeyPair({ bits: 2048 }, function (err, keyPair) {
      if (err) return console.log(`onGenerateKeys:err`, err);
      const publicKeyPem = forge.pki.publicKeyToPem(keyPair.publicKey);
      const privateKeyPem = forge.pki.privateKeyToPem(keyPair.privateKey);
      setPublicKey(publicKeyPem);
      setPrivateKey(privateKeyPem);
    });
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  height: 250px;
`;

S.EditorContainer = styled.div`
  flex: 1;
  display: flex;
  height: 100%;
  opacity: 0.6;
`;

S.InnerEditor = styled.div`
  flex: 1;
  &:nth-child(1) { margin-right: 10px; }
`;

S.ButtonContainer = styled.div`
  width: 120px;
  margin-left: 10px;
`;

S.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 30px;
  border-radius: 6px;
  margin-bottom: 5px;
  background: ${p => p.$color || p.theme.accent};
  border: 1px solid ${p => p.$color || 'rgba(31, 35, 40, 0.15)'};
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color || p.theme.accent))}; }
`;
