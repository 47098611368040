/**
 *
 *
 *
 */
import React from 'react';
import * as Router from 'react-router-dom';
import Profile from '../components/Profile';
import ProfileLearner from '../components/Profile.Learner';
import ProfileManager from '../components/Profile.Manager';
import ProfileOther from '../components/Profile.Other';
import ProfileUser from '../components/Profile.User';
import * as Store from '../store';
import * as u from '../../utils';
import * as F from '../frgs';


/**
 *
 *
 *
 */
export default function User() {

  const { state } = React.useContext(Store.Context);
  const { unix } = Router.useParams();
  const [ info, setInfo ] = React.useState();
  const [ calendar, setCalendar ] = React.useState();
  const isMe = state?.user?.unix === unix;
  React.useEffect(() => { onGetProfile(); }, [unix]);
  React.useEffect(() => { onGetCalendar(); }, [info?.id]);
  if (!info) return null;
  if (isMe && state?.user?.is_manager) return <ProfileManager calendar={calendar} />;
  if (isMe && state?.user?.role === 'LEARNER') return <Profile isMe={isMe} user={info} calendar={calendar} />;
  if (isMe && state?.user?.role === 'ADMIN') return <Profile isMe={isMe} user={info} calendar={calendar} />;
  if (isMe) return <Profile isMe={isMe} user={info} calendar={calendar} />;
  return <Profile isMe={isMe} user={info} calendar={calendar} />;

  /**
   *
   *
   *
   */
  async function onGetProfile() {
    const [data, err] = await u.fetcher(F.FRG_GET_PROFILE, { unix });
    if (err) return console.log(`onGetProfile:err`, err);
    setInfo(data);
  }

  /**
   *
   *
   *
   */
  async function onGetCalendar() {
    if (!info?.id) return;
    const [data, err] = await u.fetcher(F.FRG_GET_USER_CALENDAR, { id: info.id });
    if (err) return console.log(`onGetCalendar:err`, err);
    setCalendar(data);
  }
}
