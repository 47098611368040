/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from './Typography';


/**
 *
 *
 *
 */
export default function NotFound() {

  return (
    <S.Container>
      <S.ContentText>
        <T.IL5>404</T.IL5>
      </S.ContentText>
    </S.Container>
  );
}


/**
 *
 *
 *
 */
var S = {};

S.Container = styled.div`
  width: 800px;
  margin: 0 auto;
`;

S.ContentText = styled.div`
  margin-top: 50px;
  background-color: white;
  border: 0.5px solid rgb(225, 228, 232);
  padding: 20px;
  border-radius: 5px;
`;