/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';


/**
 *
 *
 *
 */
export default function ProfileLearner(props) {

  return (
    <S.Container>
      Learner
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div``;