/**
 *
 *
 *
 */
import React from 'react';
import { rgba } from 'polished';
import styled from 'styled-components';
import * as d3 from 'd3';
import T from './Typography';


/**
 *
 *
 *
 */
export default function Gauge(props) {
  const ref = React.useRef();
  React.useEffect(onInitialise, [ref.current, props.data, props.opts, props.lvl]);
  var { M, W, H } = props.opts; W = W - M.L - M.R; H = H - M.T - M.B;
  return (
    <S.Container>
      <S.SVG ref={ref} />
      <S.RowContainer>
        {props.hrs.map(onHours)}
      </S.RowContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onHours(elm, idx) {
    const isBold = idx === 0;
    return (
      <S.RowItem key={idx}>
        <T.IM3 $isBold={isBold}>
          {elm.title}
        </T.IM3>
        <T.IM3 $isBold={isBold}>
          {elm?.modValue || elm?.value || 0}
        </T.IM3>
      </S.RowItem>
    );
  }

  /**
   *
   *
   *
   */
  function onInitialise() {

    const RAD = 90;
    const GAP = 0.02;

    const svg = d3.select(ref.current)
      .attr('width', W)
      .attr('height', H)
      .append('g')
      .attr('transform', `translate(${W/2},${H})`);

    const arc = d3.arc()
      .innerRadius(50)
      .outerRadius(RAD)
      .cornerRadius(4);

    const tip = d3.arc()
      .innerRadius(45)
      .outerRadius(48)
      .cornerRadius(1);

    const SEC = [
      { strAng: 0.524 + GAP, endAng: 1.571,       color: '#38b000', status: 'GOOD' },
      { strAng: -.524 + GAP, endAng: 0.524 - GAP, color: '#ccff33', status: 'PASS' },
      { strAng: -1.57,       endAng: -.524 - GAP, color: '#ff2828', status: 'RISK' },
    ];

    const TIP = SEC.find(x => x.status === props.lvl);

    svg.selectAll('.pie')
      .data(SEC)
      .enter()
      .append('path')
      .attr('d', d => arc({ startAngle: d.strAng, endAngle: d.endAng }))
      .attr('fill', d => (d.status === props.lvl) ? d.color : rgba(d.color, 0.1))
      .attr('stroke', d => (d.status === props.lvl) ? d.color : 'rgb(208, 215, 222)');

    svg.selectAll('.tip')
      .data([TIP])
      .enter()
      .append('path')
      .attr('d', d => tip({ startAngle: d.strAng, endAngle: d.endAng }))
      .attr('fill', d => d.color)
      .attr('opacity', 1);

    svg.append('text')
      .text(props.lvl)
      .attr('text-anchor', 'middle')
      .attr('font-size', '18px')
      .attr('font-weight', '500')
      .attr('font-family', 'Inter')
      .attr('fill', d => SEC.find(x => x.status === props.lvl).color)
      .attr('dy', -1);

    return () => { d3.select(ref.current).selectAll('*').remove(); };
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  display: flex;
  flex-direction: column;
`;

S.SVG = styled.svg``;

S.RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 5px;
`;

S.RowItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 2px;
`;
