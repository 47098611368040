/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { DateTime } from 'luxon';
import T from './Typography';
import * as u from '../utils';


/**
 *
 *
 *
 */
const SIZE = 11;
const MONTHS = [['Jan', 5], ['Feb', 4], ['Mar', 5], ['Apr', 4], ['May', 4], ['Jun', 5], ['Jul', 4], ['Aug', 4], ['Sep', 5], ['Oct', 4], ['Nov', 4], ['Dec', 5]];
const WEEK_DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const WEEK_SHORT = { Sunday: 'Sun', Monday: 'Mon', Tuesday: 'Tue', Wednesday: 'Wed', Thursday: 'Thu', Friday: 'Fri', Saturday: 'Sat' };
const MONTH_SHORT = { Jan: 1, Feb: 2, Mar: 3, Apr: 4, May: 5, Jun: 6, Jul: 7, Aug: 8, Sep: 9, Oct: 10, Nov: 11, Dec: 12 };
const SHOW_ONLY = ['Monday', 'Wednesday', 'Friday'];
const SCORES = [ 'rgba(239, 239, 242, 0.6)', 'rgb(155, 233, 168)', 'rgb(64, 196, 99)', 'rgb(48, 161, 78)', 'rgb(33, 110, 57)' ];


/**
 *
 *
 *
 */
GitHubLikeCalendar.defaultProps = {
  year: undefined,
  month: undefined,
  calendar: undefined,
  scores: undefined,
  onClick: () => {},
};


/**
 *
 *
 *
 */
export default function GitHubLikeCalendar(props) {

  const $size = props.$size ?? SIZE;
  const maxNum = React.useMemo(() => onGetMax(props.calendar), [props.calendar]);
  const daysObject = React.useMemo(() => onGroupActivityByDay(props.calendar, props.month), [props.calendar]);
  const days = React.useMemo(() => u.onGenYearCalendar(props.year, props.month), [props.year]);
  const currMonths = MONTHS.filter(m => props.month ? m[0] === DateTime.local().set({ month: props.month }).toFormat('LLL') : m);
  const daysInWeeks = groupByWeekDay(days);
  if (!props.calendar) return null;

  return (
    <S.Container>
      <S.Table>
        {!props.month && (
          <S.TableHeader>
            <S.TableHeaderRow>
              <S.TableHeaderCell key={'000'} style={{width: 28}} />
              {currMonths.map(onHeaderCell)}
            </S.TableHeaderRow>
          </S.TableHeader>
        )}
        <S.TableBody>
          {WEEK_DAYS.map(onRowElm)}
        </S.TableBody>
      </S.Table>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onHeaderCell(arr) {
    return (
      <S.TableHeaderCell colSpan={arr[1]} key={arr[0]} $isSingleMonth={!!props.month}>
        <T.SM4 color={'rgb(31, 35, 40)'}>
          {arr[0]}
        </T.SM4>
      </S.TableHeaderCell>
    );
  }

  /**
   *  This row will really encapsulate the whole
   *  year on a week day bases. This means that
   *  the data will contain all Mondays, Tuesdays
   *  and so on.
   */
  function onRowElm(dayWeek) {
    return (
      <S.TableRow key={dayWeek}>
        {daysInWeeks[dayWeek].map(onDayCellElm)}
      </S.TableRow>
    );
  }

  /**
   *  This is the most important part of the Calendar component.
   *  We loop through every day of the "expanded" year or month.
   *  Because we have an expanded year, we need to render the
   *  extra days as invisible boxes.
   *
   *  isDesc: represents a flag object to indicate that all
   *  Mondays, Tuesdays and so on are rendered as a single line.
   *
   */
  function onDayCellElm(day) {
    const isDesc = !!day.isDesc;
    const currNum = daysObject[day.date]?.num || 0;
    const score = isDesc ? 0 : Math.ceil((currNum / maxNum) * 4);
    const numMonth = MONTH_SHORT[day.month];
    const isEmpty = !props.month ? day.year !== props.year && !isDesc : Number(numMonth) !== Number(props.month) && !isDesc;
    const key = (day.date || day.day);
    const scores = props.scores ?? SCORES;

    return (
      <S.TableCell key={key} $isDesc={isDesc} $score={score} $isEmpty={isEmpty} $size={$size} $scores={scores}>
        {isDesc && day.isShow && (<T.SM4>{day.day}</T.SM4>)}
        {day.date  && (
          <S.SpanContainer>
            <T.SL5 color={'rgb(255, 255, 255)'}>
              {currNum > 0 ? currNum : 'No'} events on {DateTime.fromFormat(day.date, 'yyyy-MM-dd').toFormat('EEE LLL dd')}
            </T.SL5>
          </S.SpanContainer>
        )}
      </S.TableCell>
    );
  }

  /**
   *
   *
   *
   */
  function onGroupActivityByDay() {
    return props.calendar?.reduce((acc, day) => {
      acc[day.date] = { ...day };
      return acc;
    }, {});
  }

  /**
   *
   *
   *
   */
  function groupByWeekDay(days) {
    return days.reduce((acc, day) => {
      const idx = day.day;
      if (!acc[idx]) acc[idx] = [{
        isDesc: true,
        isShow: SHOW_ONLY.includes(idx),
        day: WEEK_SHORT[idx],
      }];
      acc[idx].push(day);
      return acc;
    }, {});
  }

  /**
   *
   *
   *
   */
  function onGetMax() {
    return props.calendar?.reduce((acc, day) => {
      const d = DateTime.fromFormat(day.date, 'yyyy-MM-dd');
      if (Number(d.year) !== Number(props.year)) return acc;
      if (props.month && Number(props.month) !== Number(d.month)) return acc;
      return Math.max(acc, day.num);
    }, 0);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
`;

S.InnerContainer = styled.div`
`;

S.Table = styled.table`
  position: relative;
  border-spacing: 4px;
  border-collapse: separate;
`;

S.TableHeader = styled.thead`

`;

S.TableBody = styled.tbody`

`;

S.TableHeaderRow = styled.tr`
  height: 15px;
`;

S.TableHeaderCell = styled.td`
  position: relative;
  p { position: absolute; top: 0px;}
  ${p => p.$isSingleMonth && `display: none;`}
`;

S.TableRow = styled.tr`

`;

S.TableCell = styled.td`
  position: relative;
  shape-rendering: geometricPrecision;
  visibility: ${p => p.$isEmpty ? `hidden` : `visible`};

  ${p => p.$isDesc ? `
    width: 28px;
    height: ${p.$size}px;
    p {
      position: absolute;
      bottom: -2px;
    }
  ` : `
    width: ${p.$size}px;
    height: ${p.$size}px;
    outline: 1px solid rgba(27, 31, 35, 0.06);
    outline-offset: -1px;
    background: ${p.$scores[p.$score || 0]};
    border-radius: 2px;
  `}

  &:hover {
    span { display: flex; }
  }
`;

S.SpanContainer = styled.span`
  display: none;
  position: absolute;
  width: 250px;
  height: 38px;
  border-radius: 6px;
  background: rgb(31, 35, 40);
  z-index: 100;
  top: -45px;
  left: 50%;
  transform: translateX(-50%);
  justify-content: center;
  align-items: center;
  &:hover { display: none; }
`;