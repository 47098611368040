/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import T from './Typography';


/**
 *
 *
 *
 */
Options.defaultProps = {
  height: 45,
};


/**
 *
 *
 *
 */
export default function Options(props) {

  return (
    <S.OptionsContainer $height={props.height}>
      {props.opts.map((option, i) => {
        const Icon = option.icon ?? (() => null);
        const isActive = i === props.idx ? 1 : 0;
        const style = option.style || {};
        const iconStyle = option.iconStyle || {};
        return (
          <S.Option key={i} $isActive={isActive} onClick={() => props.set(i)} style={style}>
            {isActive ? <S.Line /> : null}
            <S.IconContainer style={iconStyle}>
              <Icon />
            </S.IconContainer>
            <S.TextContainer>
              <T.IX6>{option.label}</T.IX6>
            </S.TextContainer>
          </S.Option>
        );
      })}
    </S.OptionsContainer>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.OptionsContainer = styled.div`
  display: flex;
  height: ${p => p.$height}px;
  width: 100%;
  align-items: center;
  border-bottom-color: rgb(237, 239, 244);
  border-bottom-style: solid;
  border-bottom-width: 1px;
`;

S.Option = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 45px;
  margin-right: 20px;
  opacity: 0.7;
  &:hover { opacity: 1; cursor: pointer; }
  ${p => p.$isActive && `opacity: 1;` }
`;

S.Line = styled.div`
  position: absolute;
  height: 2px;
  background: #FD8C73;
  width: 100%;
  bottom: -1px;
`;

S.IconContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;

S.TextContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 5px;
  @media (max-width: 600px) {
    margin-left: 2px;
    p { font-size: 15px; }
  }
`;
