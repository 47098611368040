/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import * as I from './Icons';
import * as u from '../utils';


/**
 *
 *
 *
 */
const currAppName = process.env.REACT_APP_NAME;


/**
 *
 *
 *
 */
const FRG_GET_ME = `
  query AccountsGetMe {
    accounts_me {
      id
      first_name
      last_name
      email
      company
      is_manager
      location
      photo
      unix
      role
      created_at
      updated_at
    }
  }
`;


/**
 *
 *
 *
 */
export default function Profile(props) {

  const [isFetching, setIsFetching] = React.useState(true);
  const [isAuth, setIsAuth] = React.useState(false);
  const [info, setInfo] = React.useState(props);
  React.useEffect(() => { onMaybeFetchMe(); }, []);
  const initials = onGetInitials();
  const isPending = info?.role === 'PENDING';
  if (!isAuth) return;

  return (
    <React.Fragment>
      {!props.photo && !isPending && (
        <S.AppContainer href={'https://apps.mlx.institute'}>
          <I.Houses style={{ transform: 'scale(1.3)' }} />
        </S.AppContainer>
      )}
      <S.Container $size={props.$size}>
        <S.LinkContainer href={onGetUrl()}>
          {info?.photo
            ? <S.Img src={info?.photo} />
            : <S.Initials>{initials}</S.Initials>
          }
        </S.LinkContainer>
      </S.Container>
    </React.Fragment>
  );

  /**
   *
   *
   *
   */
  async function onMaybeFetchMe() {

    try {
      if (props.photo) return setIsAuth(true);
      const [data, err] = await u.fetcher(FRG_GET_ME);
      if (err) return console.log(`Profile:err`, err);
      if (!data?.unix) return setIsFetching(false);
      setIsAuth(true); setInfo(data);
    } catch(err) {
      console.error(`err`, err);
    } finally {
      setIsFetching(false);
    }
  }

  /**
   *
   *
   *
   */
  function onGetInitials() {
    return [
      (info?.first_name ?? [])[0] ?? '',
      (info?.last_name ?? [])[0] ?? '',
    ].join('').trim();
  }

  /**
   *
   *
   *
   */
  function onGetUrl() {
    if (isPending) return undefined;
    if (currAppName === 'mobilave' && info.role === 'PENDING') return `https://mobilave.mlx.institute/${info?.unix}`;
    return `https://accounts.mlx.institute/${info?.unix}`;
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  position: relative;
  background: lightgray;
  max-width: ${p => p.$size ?? 35}px;
  max-height: ${p => p.$size ?? 35}px;
  min-width: ${p => p.$size ?? 35}px;
  min-height: ${p => p.$size ?? 35}px;
  border-radius: 50%;
  overflow: hidden;
  border: 1px solid rgb(208, 215, 222);
  &:hover { cursor: pointer; opacity: 0.8; }
`;

S.LinkContainer = styled.a`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background: rgb(246, 248, 250);
  align-items: center;
  text-decoration: none;
  cursor: pointer;
`;

S.Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

S.Initials = styled.p`
  font-size: 14px;
  color: rgb(101, 109, 118);
  font-family: 'Inter';
  text-decoration: none;
`;

S.AppContainer = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 35px;
  max-height: 35px;
  min-width: 35px;
  min-height: 35px;
  margin-right: 12px;
  text-decoration: none;
  &:hover { cursor: pointer; opacity: 0.8; }
`;