/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import { darken, lighten, saturate } from 'polished';
import GitHubLikeCalendar from '../../_components/GitHubLikeCalendar';
import ImagePreview from './ImagePreview';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import * as u from '../../utils';
import * as F from '../frgs';


/**
 *
 *
 *
 */
export default function Profile(props) {

  const [isEditing, setIsEditing] = React.useState(false);
  const [company, setCompany] = React.useState(props.user?.company ?? '');
  const [location, setLocation] = React.useState(props.user?.location ?? '');
  const [linkedin, setLinkedin] = React.useState(props.user?.linkedin ?? '');
  const name = [props.user?.first_name, props.user?.last_name].filter(e => e).join(' ').trim();

  return (
    <S.Container>
      <S.InfoContainer>
        <S.ImagePreviewContainer>
          <ImagePreview user={props?.user} isMe={props.isMe} />
        </S.ImagePreviewContainer>
        {isEditing ? onShowUpdate() : (
          <React.Fragment>
            <T.SH6 color={'rgb(31, 35, 40)'}>
              {name}
            </T.SH6>
            <T.SO3 color={'rgb(101, 109, 118)'}>
              {props.user?.unix}
            </T.SO3>
            {props.isMe && (
              <S.Button onClick={() => setIsEditing(true)}>
                <T.IL6>Edit profile</T.IL6>
              </S.Button>
            )}
            <div style={{ height: 20 }} />
            <S.WorkLocationItem>
              <I.Briefcase />
              <div style={{ width: 5 }} />
              <T.IL5>{company ?? '-'}</T.IL5>
            </S.WorkLocationItem>
            <S.WorkLocationItem>
              <I.Location style={{ transform: 'scale(1.1)' }} />
              <div style={{ width: 5 }} />
              <T.IL5>{location || '-'}</T.IL5>
            </S.WorkLocationItem>
            <S.WorkLocationItem>
              <I.Mail />
              <div style={{ width: 5 }} />
              <T.IL5>{props.user?.email || '-'}</T.IL5>
            </S.WorkLocationItem>
            <S.WorkLocationItem>
              <I.Linkedin style={{ transform: 'scale(1.1)' }} />
              <div style={{ width: 5 }} />
              <T.IL5>{onDisplayLink(linkedin) || '-'}</T.IL5>
            </S.WorkLocationItem>
          </React.Fragment>
        )}
        <div style={{position: 'relative'}}>
          <S.ComingSoon>
            <T.IH5>Coming soon</T.IH5>
          </S.ComingSoon>
          <S.AchievementsContainer>
            <T.SX6>Achievements</T.SX6>
            <S.AchievementsInner>
              <img src="https://github.githubassets.com/images/modules/profile/achievements/starstruck-default.png" width="64" />
              <img src="https://github.githubassets.com/images/modules/profile/achievements/pair-extraordinaire-default.png" width="64" />
              <img src="https://github.githubassets.com/images/modules/profile/achievements/pull-shark-default.png" width="64" />
              <img src="https://github.githubassets.com/images/modules/profile/achievements/galaxy-brain-default.png" width="64" />
              <img src="https://github.githubassets.com/images/modules/profile/achievements/quickdraw-default.png" width="64" />
            </S.AchievementsInner>
          </S.AchievementsContainer>
          <S.GroupsContainer>
            <T.SX6>Groups</T.SX6>
            <S.GroupsInner>
              <S.GroupItem />
              <S.GroupItem />
              <S.GroupItem />
              <S.GroupItem />
            </S.GroupsInner>
          </S.GroupsContainer>
        </div>
      </S.InfoContainer>
      <S.ActivityContainer>
        {/* <T.SX5>{props.calendar?.total || 0} contributions in the last year</T.SX5> */}
        {/* <div style={{ height: 10 }} /> */}
        <GitHubLikeCalendar calendar={props?.calendar?.calendar} year={'2024'} />
      </S.ActivityContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onDisplayLink(link) {
    if (!link) return null;
    return link.replace(/(https?:\/\/)?(www\.)?linkedin\.com\/in\/|\/$/g, '');
  }

  /**
   *
   *
   *
   */
  function onShowUpdate() {
    return (
      <form onSubmit={onUpdateProfile}>
        <div style={{ marginBottom: 10 }}>
          <S.SectionTopContainer>
            <T.IL5>Company</T.IL5>
          </S.SectionTopContainer>
          <S.Input
            type="string"
            name="company"
            onChange={e => setCompany(e.target.value)}
            value={company}
          />
        </div>
        <div style={{ marginBottom: 10 }}>
          <S.SectionTopContainer>
            <T.IL5>Location</T.IL5>
          </S.SectionTopContainer>
          <S.Input
            type="string"
            name="location"
            onChange={e => setLocation(e.target.value)}
            value={location}
          />
        </div>
        <div>
          <S.SectionTopContainer>
            <T.IL5>Linkedin</T.IL5>
          </S.SectionTopContainer>
          <S.Input
            type="string"
            name="linkedin"
            onChange={e => setLinkedin(e.target.value)}
            value={linkedin}
          />
        </div>
        <div style={{display: 'flex'}}>
          <S.Button $color={'#FD8C73'} type="submit">
            <T.IL6 color={'white'}>Save</T.IL6>
          </S.Button>
          <div style={{minWidth: 10}} />
          <S.Button onClick={() => setIsEditing(false)}>
            <T.IL6>Cancel</T.IL6>
          </S.Button>
        </div>
      </form>
    );
  }

  /**
   *
   *
   *
   */
  async function onUpdateProfile(evt) {
    evt.preventDefault();
    const raw = new FormData(evt.target);
    const form = { id: props.user.id };
    for (const [k, v] of raw.entries()) form[k] = v;
    const [_, err] = await u.fetcher(F.FRG_UPDATE_USER, form);
    if (err) return console.log('onUpdateProfile:err', err);
    setIsEditing(false);
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  margin-top: 20px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.InfoContainer = styled.div`
  margin-right: 50px;
  width: 280px;
  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

S.ImagePreviewContainer = styled.div`
  margin-bottom: 15px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

S.ActivityContainer = styled.div`
  flex: 1;
  position: relative;
  height: fit-content;
  @media (max-width: 600px) {
    display: none;
  }
`;

S.ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

S.Button = styled.button`
  width: 100%;
  height: 35px;
  border-radius: 6px;
  background: ${p => p.$color ?? 'rgb(243, 244, 246)'};
  border: 1px solid rgba(31, 35, 40, 0.15);
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color ?? 'rgb(243, 244, 246)'))}; }
  margin-top: 20px;
`;

S.WorkLocationItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

S.AchievementsContainer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  // border-top: 1px solid rgb(216, 222, 228)
`;

S.AchievementsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
`;

S.GroupsContainer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgb(216, 222, 228)
`;

S.GroupsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
`;

S.GroupItem = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 8px;
  margin-bottom: 5px;
  border-radius: 6px;
  background: rgb(235, 237, 240);
`;

S.Initials = styled.p`
  font-size: 90px;
  color: rgb(101, 109, 118);
  font-family: 'Inter';
`;

S.ComingSoon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(240, 240, 240, 0.95);
  border-radius: 9px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.Input = styled.input`
  width: 100%;
  height: 35px;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 6px;
  padding-left: 6px;
  font-family: 'Inter';
  font-size: 14px;
`;

S.SectionTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  p:nth-child(2) {
    cursor: pointer;
    &:hover { color: ${lighten(0.1, saturate(0.1, '#FD8C73'))} }
  }
`;

S.FormContainer = styled.form`
`;