/**
 *
 *
 *
 */
import React from 'react';
import * as R from 'ramda';
import { DateTime } from 'luxon';
import styled from 'styled-components';
import { darken, lighten, saturate } from 'polished';
import GitHubLikeCalendar from '../../_components/GitHubLikeCalendar';
import Options from '../../_components/Options';
import * as G from '../../_components/Graphs';
import T from '../../_components/Typography';
import * as I from '../../_components/Icons';
import ImagePreview from './ImagePreview';
import * as Store from '../store';
import * as u from '../../utils';


/**
 *
 *
 *
 */
const FRG_GET_APPRENTICES_INFO = `
  query AccountsGetApprenticesInfo {
    accounts_get_apprentices_info
  }
`;


/**
 *
 *
 *
 */
const TIME_OPTS = { W: 690, H: 200, M: { T: 10, B: 25, L: 0, R: 140 } };
const GUGE_OPTS = { W: 180, H: 90, M: { T: 0, B: 0, L: 0, R: 0 } };


/**
  #F94144
  #F3722C
  #F8961E
  #F9844A
  #F9C74F
  #90BE6D
  #43AA8B
  #4D908E
  #577590
  #277DA1
 */
const TOPIC = {
  'one2one': '#F94144',
  'mentoring': '#277DA1',
  'tripartite': '#5A189A',
  'intensive': '#F9C74F',
  'workshop': '#90BE6D',
  'prior_learning': '#577590',
  'other': '#F8961E',
  'interview': '#F9844A',
  'safeguarding': '#43AA8B',
  'clear_expectations': '#4D908E',
  'prior_knowledge': '#F3722C',
};


/**
 *
 *
 *
 */
const OPTIONS = [
  { label: 'Profile',     icon: () => <I.Profile style={{transform: 'scale(1.2)'}} />, style: { width: 90, height: 50 } },
  { label: 'Apprentices', icon: I.SchoolFigma, style: { width: 150, marginLeft: 'auto', marginRight: 0, height: 50 } },
];


/**
 *
 *
 *
 */
export default function ProfileManager(props) {

  const [ currTab, setCurrTab ] = React.useState();
  const [ learners, setLearners ] = React.useState([]);
  const { state } = React.useContext(Store.Context);
  React.useEffect(() => { onApprenticesInfo(); }, []);
  const thisMonth = DateTime.now().toFormat('yyyy-MM');
  const users = learners.map((l) => u.onCalculateStatusHours(state, l, thisMonth));
  const name = [state.user?.first_name, state.user?.last_name].filter(e => e).join(' ').trim();

  return (
    <S.Container>
      <S.InfoContainer>
        <S.ImagePreviewContainer>
          <ImagePreview user={state?.user} isMe={true} />
        </S.ImagePreviewContainer>
        {onShowInfo()}
        {onShowAchieve()}
      </S.InfoContainer>
      <S.ActivityContainer>
        <S.OptionsContainer>
          <Options {...onGetOptsProps()} />
        </S.OptionsContainer>
        {onCurrTabComponent()}
      </S.ActivityContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function onCurrTabComponent() {
    if (currTab === 0) return (
      <GitHubLikeCalendar calendar={props?.calendar?.calendar} year={'2024'} />
    );

    if (currTab === 1) return (
      <S.LearnersContainer>
        {users.map(onLearnerGauge)}
      </S.LearnersContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onGetOptsProps() {
    return {
      opts: OPTIONS,
      idx: currTab,
      set: setCurrTab,
      height: 50,
    };
  }

  /**
   *
   *
   *
   */
  function onShowInfo() {
    return (
      <React.Fragment>
        <T.SH6 color={'rgb(31, 35, 40)'}>
          {name}
        </T.SH6>
        <T.SO3 color={'rgb(101, 109, 118)'}>
          {state.user?.unix}
        </T.SO3>
        <S.Button>
          <T.IL6>Edit profile</T.IL6>
        </S.Button>
        <div style={{ height: 20 }} />
        <S.WorkLocationItem>
          <I.Briefcase />
          <div style={{ width: 5 }} />
          <T.IL5>{state.user.company ?? '-'}</T.IL5>
        </S.WorkLocationItem>
        <S.WorkLocationItem>
          <I.Location style={{ transform: 'scale(1.1)' }} />
          <div style={{ width: 5 }} />
          <T.IL5>{state.user.location || '-'}</T.IL5>
        </S.WorkLocationItem>
        <S.WorkLocationItem>
          <I.Mail />
          <div style={{ width: 5 }} />
          <T.IL5>{state.user?.email || '-'}</T.IL5>
        </S.WorkLocationItem>
        <S.WorkLocationItem>
          <I.Linkedin style={{ transform: 'scale(1.1)' }} />
          <div style={{ width: 5 }} />
          <T.IL5>{onDisplayLink(state.user.linkedin) || '-'}</T.IL5>
        </S.WorkLocationItem>
      </React.Fragment>
    );
  }

  /**
   *
   *
   *
   */
  function onShowAchieve() {
    return (
      <div style={{ position: 'relative' }}>
        <S.ComingSoon>
          <T.IH5>Coming soon</T.IH5>
        </S.ComingSoon>
        <S.AchievementsContainer>
          <T.SX6>Achievements</T.SX6>
          <S.AchievementsInner>
            <img src="https://github.githubassets.com/images/modules/profile/achievements/starstruck-default.png" width="64" />
            <img src="https://github.githubassets.com/images/modules/profile/achievements/pair-extraordinaire-default.png" width="64" />
            <img src="https://github.githubassets.com/images/modules/profile/achievements/pull-shark-default.png" width="64" />
            <img src="https://github.githubassets.com/images/modules/profile/achievements/galaxy-brain-default.png" width="64" />
            <img src="https://github.githubassets.com/images/modules/profile/achievements/quickdraw-default.png" width="64" />
          </S.AchievementsInner>
        </S.AchievementsContainer>
        <S.GroupsContainer>
          <T.SX6>Groups</T.SX6>
          <S.GroupsInner>
            <S.GroupItem />
            <S.GroupItem />
            <S.GroupItem />
            <S.GroupItem />
          </S.GroupsInner>
        </S.GroupsContainer>
      </div>
    );
  }

  /**
   *
   *
   *
   */
  function onLearnerGauge(elm) {

    const ts = R.last(elm.stats);
    const date = DateTime.fromISO(ts.ds).toFormat('dd LLL yyyy');

    return (
      <S.LearnerContainer key={elm.id} $isLista={false}>
        <S.ImageContainer $isLista={false}>
          <S.Image $isLista={false} src={elm?.photo} />
          <div style={{ minHeight: 10 }} />
          <T.IL5 style={{ textAlign: 'center' }}>
            {elm.name}
          </T.IL5>
          <div style={{ minHeight: 5 }} />
          <T.ISX4>Last online: {date}</T.ISX4>
        </S.ImageContainer>
        <G.Gauge
          opts={GUGE_OPTS}
          lvl={elm.status}
          hrs={elm.hours}
        />
      </S.LearnerContainer>
    );
  }

  /**
   *
   *
   *
   */
  function onDots(elm) {
    return elm.reports?.reduce((acc, rpt) => {
      if (rpt.type !== 'TIME_POINT') return acc;
      const date = rpt.event_time;
      rpt.topics?.forEach((t, i) => acc.push({ date, $topic: t, $bg: TOPIC[t], $type: 'dots', $idx: i }));
      return acc;
    }, []);
  }

  /**
   *
   *
   *
   */
  function onAreas(elm) {
    if (!elm) return [];
    const area = [];
    const rangRpts = elm.reports?.filter(rpt => rpt.type === 'TIME_RANGE');
    area.push({ start: elm.cohort?.start, ended: R.min(DateTime.local(), DateTime.fromISO(elm.cohort?.ended)), $bg: TOPIC['intensive'], $topic: 'Intensive Program', $type: 'area' });
    area.push(...elm.events?.map(evt => ({ ...evt, start: evt.starts_at, ended: evt.ending_at, $bg: TOPIC['workshop'], $topic: 'Talks & Workshops' })));
    area.push(...rangRpts?.map(rpt => ({ start: rpt.starts_at, ended: rpt.ending_at, $bg: '#bb4fcd', $topic: 'Other' })));
    return area.map(e => ({ ...e, $type: 'area' }));
  }

  /**
   *
   *
   *
   */
  async function onApprenticesInfo() {
    const [data, err] = await u.fetcher(FRG_GET_APPRENTICES_INFO);
    if (err) return console.log(`onApprenticesInfo:err`, err);
    setLearners(data);
  }

  /**
   *
   *
   *
   */
  function onDisplayLink(link) {
    if (!link) return null;
    return link.replace(/(https?:\/\/)?(www\.)?linkedin\.com\/in\/|\/$/g, '');
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 100%;
  display: flex;
  padding-bottom: 30px;
  @media (max-width: 600px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.OptionsContainer = styled.div`
  margin-bottom: 15px;
`;

S.InfoContainer = styled.div`
  margin-right: 50px;
  margin-top: 20px;
  width: 280px;
  @media (max-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

S.ImagePreviewContainer = styled.div`
  margin-bottom: 15px;
  @media (max-width: 600px) {
    display: flex;
    justify-content: center;
  }
`;

S.ActivityContainer = styled.div`
  flex: 1;
  position: relative;
  height: fit-content;
  @media (max-width: 600px) { display: none; }
`;

S.ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

S.Button = styled.button`
  width: 100%;
  height: 35px;
  border-radius: 6px;
  background: ${p => p.$color ?? 'rgb(243, 244, 246)'};
  border: 1px solid rgba(31, 35, 40, 0.15);
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color ?? 'rgb(243, 244, 246)'))}; }
  margin-top: 20px;
`;

S.WorkLocationItem = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 9px;
`;

S.AchievementsContainer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
`;

S.AchievementsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
`;

S.GroupsContainer = styled.div`
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid rgb(216, 222, 228)
`;

S.GroupsInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-top: 10px;
`;

S.ComingSoon = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(240, 240, 240, 0.95);
  border-radius: 9px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`;

S.Initials = styled.p`
  font-size: 90px;
  color: rgb(101, 109, 118);
  font-family: 'Inter';
`;

S.GroupItem = styled.div`
  height: 40px;
  width: 40px;
  margin-right: 8px;
  margin-bottom: 5px;
  border-radius: 6px;
  background: rgb(235, 237, 240);
`;

S.SectionTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  p:nth-child(2) {
    cursor: pointer;
    &:hover { color: ${lighten(0.1, saturate(0.1, '#FD8C73'))} }
  }
`;

S.LearnerContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 200px;
  border-radius: 4px;
  border: 1px solid rgb(208, 215, 222);
  ${p => p.$isLista && `margin-bottom: 10px;`}
`;

S.ImageContainer = styled.div`
  width: 210px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 5px 0px 5px;
  border-right: 1px solid rgb(208, 215, 222);
  background: rgb(246, 248, 250);
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
`;

S.Image = styled.img`
  width: 140px;
  height: 140px;
  border-radius: 50%;
  border: 1px solid rgb(208, 215, 222);
  object-fit: cover;
`;

S.LearnersContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 10px;
`;
