/**
 *
 *
 *
 */
import React from 'react';
import { darken, lighten, saturate } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import Spinner from '../../_components/Spinner';
import T from '../../_components/Typography';


/**
 *
 *
 *
 */
const COHORTS = [
  { name: 'MLX1', value: 1 },
  { name: 'MLX2', value: 2 },
  { name: 'MLX3', value: 3 },
  { name: 'FAC',  value: 4 },
];


/**
 *
 *
 *
 */
const HOUSES = [
  { name: 'Gradient Gigglers', value: 1 },
  { name: 'Perceptron Party', value: 2 },
  { name: 'Backprop Bunch', value: 3 },
  { name: 'Overfitting Overlords', value: 4 },
  { name: 'Recurrent Rebels', value: 7 },
];


/**
 *
 *
 *
 */
export default function Register() {

  const nav = Router.useNavigate();
  const [ cohort, setCohort ] = React.useState('');
  const [ house, setHouse ] = React.useState('');
  const [ isLoading, setIsLoading ] = React.useState(false);
  const [ error, setError ] = React.useState();

  return (
    <S.Container>
      <S.TopImage src="/assets/mlx.png" />
      <S.FormContainer onSubmit={onSubmit} method="POST" action="/register">
        {process.env.NODE_ENV === 'development' && (
          <React.Fragment>
            <S.Button $color={'#FD8C73'} onClick={onCreateFake}>
              <T.IL6 color="white">Fake Data</T.IL6>
            </S.Button>
            <div style={{ height: 15 }} />
          </React.Fragment>
        )}
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>First Name</T.IL5>
          </S.SectionTopContainer>
          <S.Input required type="string" name="first_name" />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Last Name</T.IL5>
          </S.SectionTopContainer>
          <S.Input required type="string" name="last_name" />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Email</T.IL5>
          </S.SectionTopContainer>
          <S.Input required type="string" name="email" />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Username</T.IL5>
            <T.IM4 color={'rgb(136, 142, 150)'}>Only lowercase letters</T.IM4>
          </S.SectionTopContainer>
          <S.Input required pattern="[a-z]+" type="string" name="unix" />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Password</T.IL5>
          </S.SectionTopContainer>
          <S.Input required type="password" name="password" />
        </S.SectionContainer>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Confirm Password</T.IL5>
          </S.SectionTopContainer>
          <S.Input required type="password" name="confirm_password" />
        </S.SectionContainer>
        {error && (
          <S.SectionContainer>
            <T.IL5 color="red">{error}</T.IL5>
          </S.SectionContainer>
        )}
        <S.Button type="submit">
          {isLoading ? <Spinner /> : <T.IL6 color="white">Register</T.IL6>}
        </S.Button>
      </S.FormContainer>
      <S.BottomContainer>
        <T.IL5>Already have an account?</T.IL5>
        <div style={{width: 5}} />
        <T.IL5 onClick={() => nav(`/login`)} color="#FD8C73">
          Sign in
        </T.IL5>
      </S.BottomContainer>
    </S.Container>
  );

  /**
   *
   *
   *
   */
  function getOption(elm, idx) {
    return (
      <option key={idx} value={elm.value}>
        {elm.name}
      </option>
    );
  }

  /**
   *
   *
   *
   */
  async function onSubmit(evt) {
    evt.preventDefault();

    const currForm = new FormData(evt.target);
    const data = {};
    for (const [k, v] of currForm.entries()) data[k] = v;

    if (data.password !== data.confirm_password) {
      return setError('Password mismatch');
    }

    try {
      setIsLoading(true);
      const headers = { 'Content-Type': 'application/json' };
      const body = JSON.stringify(data);
      const opts = { method: 'POST', credentials: 'include', headers, body };
      const res = await (await fetch('https://accounts.mlx.institute/register', opts)).json();
      if (res.unix_taken) throw Error('Username already in use');
      if (res.email_taken) throw Error('Email already is use');
      if (res.password_mismatch) throw Error('Password mismatch');
      if (res.error) throw Error(res.error);
      // window.location.href = `https://accounts.mlx.institute`;
      window.location.reload(true);
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
    }
  }

  /**
   *
   *
   *
   */
  function onCreateFake(evt) {
    evt.preventDefault();
    document.querySelector('input[name="first_name"]').value = 'John';
    document.querySelector('input[name="last_name"]').value = 'Doe';
    document.querySelector('input[name="email"]').value = 'bes@bes.com';
    document.querySelector('input[name="unix"]').value = 'asdfasdf';
    // document.querySelector('select[name="cohort"]').value = 2;
    document.querySelector('input[name="password"]').value = 'correct';
    document.querySelector('input[name="confirm_password"]').value = 'correct';
  }
};


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 400px;
  margin: 0px auto;
  padding-bottom: 50px;
  margin-top: 20px;
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.TopImage = styled.img`
  width: 400px;
  height: 161px;
  object-fit: cover;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

S.FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid rgb(216, 222, 228);
  background: rgb(246, 248, 250);
`;

S.BottomContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100px;
  width: 100%;
  margin-top: 25px;
  border-radius: 6px;
  border: 1px solid rgb(216, 222, 228);
  p:nth-child(3) {
    &:hover {
      cursor: pointer;
      color: ${lighten(0.1, saturate(0.1, '#FD8C73'))}
    }
  }
`;

S.SectionContainer = styled.div`
  margin-bottom: 20px;
`;

S.SectionTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  p:nth-child(2) {
    cursor: pointer;
    &:hover { color: ${lighten(0.1, saturate(0.1, '#FD8C73'))} }
  }
`;

S.Input = styled.input`
  width: 100%;
  height: 40px;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 6px;
  padding-left: 6px;
  font-family: 'Inter';
  font-size: 14px;
`;

S.Select = styled.select`
  width: 100%;
  height: 40px;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 6px;
  padding-left: 6px;
  appearance: none;
  &:hover { cursor: pointer; }
  ${p => p.$isFade && `
    color: rgba(0, 0, 0, 0.4);
  `}
`;

S.Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

S.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background: ${p => p.$color || p.theme.accent};
  border: 1px solid ${p => p.$color || 'rgba(31, 35, 40, 0.15)'};
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color || p.theme.accent))}; }
`;
