/**
 *
 *
 *
 */
import React from 'react';
import ReactDOM from 'react-dom/client';


/**
 *
 *
 *
 */
const { default: App } = require(`./${process.env.REACT_APP_NAME}`);
// const { default: App } = require('./_stories/_index');
// const { default: App } = require('./${process.env.REACT_APP_NAME}/_stories/_index`);


/**
 *
 *
 *
 */
const root = ReactDOM.createRoot(document.getElementById('root'));


/**
 *
 *
 *
 */
// root.render(<React.StrictMode><App /></React.StrictMode>);
root.render(<App />);
