/**
 *
 *
 *
 */
import React from 'react';
import { produce } from 'immer';
import * as u from '../utils';
import * as F from './frgs';


/**
 *
 *
 *
 */
export const init = {
  profile: undefined,
  isAuth: false,
  user: undefined,
};


/**
 *
 *
 *
 */
export const reducer = produce((state = init, action) => {
  switch (action.type) {
    case 'SET_USER':
      state.user = action.user;
      return state;
    case 'SET_IS_AUTH':
      state.isAuth = action.isAuth;
      return state;
    default:
      return state;
  }
});


/**
 *
 *
 *
 */
export const getActions = (state, dispatch) => {

  /**
   *
   *
   *
   */
  async function onGetMe() {
    const [data, err] = await u.fetcher(F.FRG_GET_ME);
    if (err) return console.log(`onGetMe:err`, err);
    dispatch({ type: 'SET_USER', user: data });
    dispatch({ type: 'SET_IS_AUTH', isAuth: true });
  }

  /**
   *
   *
   *
   */
  async function onUpdateKeys(public_key, private_key) {
    const payload = { id: state.user.id, public_key, private_key };
    const [_, err] = await u.fetcher(F.FRG_UPDATE_USER, payload);
    if (err) return console.log(`onUpdateKeys:err`, err);
  }

  return {
    onGetMe,
    onUpdateKeys
  };
};


/**
 *
 *
 *
 */
export const Context = React.createContext({});


/**
 *
 *
 *
 */
export const Provider = (props) => {

  const currReducer = props.reducer ?? reducer;
  const currState = props.state ?? init;
  const [state, dispatch] = React.useReducer(currReducer, currState);
  const actions = getActions(state, dispatch);
  React.useEffect(() => { actions.onGetMe(); }, []);

  return (
    <Context.Provider value={{ state, dispatch, actions }}>
      {props.children}
    </Context.Provider>
  );
};
