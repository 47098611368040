/**
 *
 *
 *
 */
import React from 'react';
import { darken, lighten, saturate } from 'polished';
import styled from 'styled-components';
import * as Router from 'react-router-dom';
import Spinner from '../../_components/Spinner';
import T from '../../_components/Typography';


/**
 *
 *
 *
 */
export default function Forgot() {

  const nav = Router.useNavigate();
  const [ showMessage, setShowMessage ] = React.useState(false);
  const [ isLoading, setIsLoading ] = React.useState(false);
  const [ error, setError ] = React.useState();

  return (
    <S.Container>
      <S.TopImage src="/assets/mlx.png" />
      <S.FormContainer onSubmit={onSubmit} method="POST" action={`/forgot`}>
        <S.SectionContainer>
          <S.SectionTopContainer>
            <T.IL5>Username or email address</T.IL5>
          </S.SectionTopContainer>
          <S.Input type="string" name="unix_email" />
        </S.SectionContainer>
        {error && (
          <S.SectionContainer>
            <T.IL5 color="red">{error}</T.IL5>
          </S.SectionContainer>
        )}
        <S.Button type="submit">
          {isLoading ? <Spinner /> : <T.IL6 color="white">Submit</T.IL6>}
        </S.Button>
      </S.FormContainer>
      <S.BottomContainer>
        <T.IL5>New to MLX?</T.IL5>
        <div style={{ width: 5 }} />
        <T.IL5 onClick={() => nav(`/register`)} color="#FD8C73">
          Create an account
        </T.IL5>
      </S.BottomContainer>
      {showMessage && (
        <S.MessageContainer style={{padding: '0px 30px'}}>
          <T.IL5 style={{ textAlign: 'center' }}>
            Check your email with instructions on how to reset your password.
          </T.IL5>
        </S.MessageContainer>
      )}
    </S.Container>
  );

  /**
   *
   *
   *
   */
  async function onSubmit(evt) {
    evt.preventDefault();
    const currForm = new FormData(evt.target);
    const data = {};
    for (const [k, v] of currForm.entries()) data[k] = v;

    try {
      setIsLoading(true);
      const headers = { 'Content-Type': 'application/json' };
      const body = JSON.stringify(data);
      await new Promise(resolve => setTimeout(resolve, 1000));
      const opts = { method: 'POST', credentials: 'include', headers, body };
      await (await fetch('https://accounts.mlx.institute/forgot', opts)).json();
      setIsLoading(false);
      setShowMessage(true);
    } catch (err) {
      setError('There was an error');
      setIsLoading(false);
    }
  }
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  width: 400px;
  margin: 0px auto;
  padding-bottom: 50px;
  margin-top: 20px;
  @media (max-width: 600px) {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
`;

S.TopImage = styled.img`
  width: 400px;
  height: 161px;
  object-fit: cover;
  margin-bottom: 50px;
  @media (max-width: 600px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`;

S.FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  border: 1px solid rgb(216, 222, 228);
  background: rgb(246, 248, 250);
`;

S.BottomContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100px;
  width: 100%;
  margin-top: 25px;
  border-radius: 6px;
  border: 1px solid rgb(216, 222, 228);
  p:nth-child(3) {
    &:hover {
      cursor: pointer;
      color: ${lighten(0.1, saturate(0.1, '#FD8C73'))}
    }
  }
`;

S.MessageContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-wrap: wrap;
  height: 100px;
  width: 100%;
  margin-top: 25px;
  border-radius: 6px;
  background: rgb(231, 241, 254);
  border: 1px solid rgb(9, 105, 218);
`;

S.SectionContainer = styled.div`
  margin-bottom: 20px;
`;

S.SectionTopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

S.Input = styled.input`
  width: 100%;
  height: 35px;
  border: 1px solid rgb(208, 215, 222);
  border-radius: 6px;
  padding-left: 6px;
  font-family: 'Inter';
  font-size: 14px;
`;

S.Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

S.Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  border-radius: 6px;
  background: ${p => p.$color || p.theme.accent};
  border: 1px solid ${p => p.$color || 'rgba(31, 35, 40, 0.15)'};
  &:hover { cursor: pointer; background: ${p => darken(0.1, (p.$color || p.theme.accent))}; }
`;