/**
 *
 *
 *
 */
import React from 'react';
import styled from 'styled-components';
import GenerateKeys from '../components/GenerateKeys';
import T from '../../_components/Typography';


/**
 *
 *
 *
 */
export default function Settings() {

  return (
    <S.Container>
      <GenerateKeys />
    </S.Container>
  );
}


/**
 *
 *
 *
 */
const S = {};

S.Container = styled.div`
  margin-top: 20px;
`;
